<template>
  <section>
    <v-container class="bannerTraining container-custom">
      <h1>
        A Comprehensive Training Program <br />
        in Nepal Focusing on Enhancing Skills <br />Rapidly for Customer Success
        Teams
      </h1>
      <p>
        Neco Job presents an extensive array of career-focused and cutting-edge
        <br />
        training initiatives meticulously crafted to elevate your skills and
        <br />
        keep you at the forefront of the competition. Enroll today and
        witness<br />
        your career soar to new heights.
      </p>
    </v-container>
    <div class="shadownBox">
      <v-container class="container-custom">
        <v-row>
          <v-col md="4">
            <div class="iconText">
              <img :src="require(`@/assets/experience.svg`)" />
              <strong>5+ Years of Experience</strong>
              <small>Learn from Instructions with Real-World Experience</small>
            </div>
          </v-col>
          <v-col md="4">
            <div class="iconText">
              <img :src="require(`@/assets/students.svg`)" />
              <strong>Students in 77 District</strong>
              <small>Learn What You Want from Anywhere at Anytime</small>
            </div>
          </v-col>
          <v-col md="4">
            <div class="iconText">
              <img :src="require(`@/assets/trainingprovider.svg`)" />
              <strong>#1 Training Provider</strong>
              <small>Leaders Love our Training Programs</small>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-container class="container-custom">
      <v-row class="mt-5">
        <v-col md="4">
          <img src="../../../assets/whyjoin.png" alt="" width="100%" />
        </v-col>
        <v-col md="8" class="bg-box">
          <h2 class="mb-2">Why join training in Neco Job?</h2>
          <p>
            Choosing Neco Jobs for your training sessions isn't just a choice;
            it's an investment in your future. Join us on a journey that
            promises to redefine your skills, reshape your mindset, and
            revolutionize your career trajectory. Your dream job is within
            reach, and Neco Jobs is here to guide you every step of the way.
          </p>
          <ul class="listJobs mt-3">
            <li>- Nepal's #1 training company</li>
            <li>- Experts with decade of experience as instructors</li>
            <li>- Placement opportunity for deserving trainees</li>
            <li>- Sufficient training resources</li>
            <li>- Regular practical classes to real work scenario</li>
          </ul>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <h1>Professional & practical skill development plate form for all</h1>

          <table>
            <v-simple-table class="table">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">Who we are</th>
                    <th class="text-center">What we do</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div class="text-justify">
                        Whether you're looking for some of the most
                        comprehensive training programs to further your career
                        or a complete solution for integrating training
                        solutions for your business, Neco leadership offers
                        endless possibilities for learners, educators, and
                        business partners.
                      </div>
                    </td>
                    <td>
                      <div class="text-justify">
                        We are identifying the gap between skill & knowledge,
                        developing instructional materials & conduct training
                        programs. We use various training method including e
                        learning , coaching , keep trainees engaged with
                        interactive session, like outdoor activities and
                        practical project work by highly Professional trainer.
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </table>
        </v-col>

        <v-col md="12" class="mr-btm">
          <div class="boxinside pagination-front" v-if="false">
            <div>Showing <span>1 - 10</span></div>
            <div>
              <ul>
                <li class="prev">Prev</li>
                <li>1</li>
                <li class="next">Next</li>
              </ul>
            </div>
          </div>
          <h1>Available Trainings</h1>
          <p class="text-center">
            Find New Opportunities And Achieve Your Professional Goals With Neco
            Jobs Comprehensive Training Courses.
          </p>
          <div class="marginbtm mt-7" v-if="!loadingTraining">
            <v-row>
              <v-col
                md="4"
                v-for="(data, index) in allTrainings.results.list"
                :key="index"
                class="py-0"
              >
                <Searchboxtraining :training="data" class="jobslistBox" />
              </v-col>
            </v-row>
          </div>
          <div class="marginbtm mt-7" v-if="loadingTraining">
            <v-row>
              <v-col md="4">
                <div class="jobslist">
                  <v-skeleton-loader
                    type="image"
                    height="200"
                  ></v-skeleton-loader>
                </div>
              </v-col>
              <v-col md="4">
                <div class="jobslist">
                  <v-skeleton-loader
                    type="image"
                    height="200"
                  ></v-skeleton-loader>
                </div>
              </v-col>
              <v-col md="4">
                <div class="jobslist">
                  <v-skeleton-loader
                    type="image"
                    height="200"
                  ></v-skeleton-loader>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="boxinside pagination-front" v-if="false">
            <div>Showing <span>1 - 10</span></div>
            <ul>
              <li class="prev">Prev</li>
              <li>1</li>
              <li class="next">Next</li>
            </ul>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="!loadingTraining" class="container-custom">
      <div class="placementBox">
        <h2 class="mb-5">Our Job Placement Partner</h2>

        <div class="my-10">
          <slick ref="slick" :options="slickOptions">
            <div
              v-for="(data, index) in allCompanyLogos.results.filter(
                (ele) => ele.imageUrl != undefined
              )"
              :key="index"
              class="imageBox"
            >
              <img class="img-fluid" width="100" :src="data.imageUrl" />
            </div>
          </slick>
        </div>
      </div>
    </v-container>
    <v-container v-if="loadingTraining" class="container-custom">
      <div class="placementBox">
        <h2 class="mb-5">Our Job Placement Partner</h2>

        <div class="mt-10 mb-10">
          <v-skeleton-loader type="image" height="100"></v-skeleton-loader>
        </div>
      </div>
    </v-container>

    <v-container class="container-custom container-placementBox">
      <div class="placementBox">
        <h2 class="mb-5">
          Testimonials from Our Stellar Students <br />on Our Remarkable
          Programs
        </h2>
      </div>

      <div width="100%">
        <slick ref="slick" :options="slickOptionss">
          <v-col>
            <div class="iconText2">
              <h5>
                As an experienced HR professional, I was seeking a training
                program that would help me stay updated with the latest industry
                practices. The Practical HR Function training by Neco Jobs
                provided exactly that. The training was well-structured and
                covered a wide range of topics, including recruitment, payroll,
                compliance, and more. The trainer demonstrated a deep
                understanding of each subject and shared real-world examples
                that made the concepts easy to grasp. This training has
                undoubtedly enhanced my skill set and added value to my role.
              </h5>
              <div class="reviewImage">
                <v-row class="reviewflex">
                  <v-col md="5">
                    <img :src="require(`@/assets/JenishaSaru.jpg`)" />
                  </v-col>
                  <v-col md="7">
                    <p>Jenisha Saru</p>
                    <div class="innerstar">
                      <v-icon>mdi-star</v-icon>
                      <v-icon>mdi-star</v-icon>
                      <v-icon>mdi-star</v-icon>
                      <v-icon>mdi-star</v-icon>
                      <v-icon>mdi-star</v-icon>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="iconText2">
              <h5>
                I had the privilege of attending the Practical HR Function
                training provided by Neco Jobs, and I must say it exceeded my
                expectations. The course content was comprehensive and covered
                every aspect of HR functions, from recruitment to compliance.
                The trainer was incredibly knowledgeable and provided practical
                insights that I could immediately apply in my role. This
                training has equipped me with the skills and confidence to excel
                in my HR career. I highly recommend this program to anyone
                looking to enhance their HR expertise.
              </h5>
              <div class="reviewImage">
                <v-row class="reviewflex">
                  <v-col md="5">
                    <img :src="require(`@/assets/AliyaHumagain.jpg`)" />
                  </v-col>
                  <v-col md="7">
                    <p>Aliya Humagain</p>
                    <div class="innerstar">
                      <v-icon>mdi-star</v-icon>
                      <v-icon>mdi-star</v-icon>
                      <v-icon>mdi-star</v-icon>
                      <v-icon>mdi-star</v-icon>
                      <v-icon>mdi-star</v-icon>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="iconText2">
              <h5>
                Attending the Practical HR Function training was a pivotal step
                in my transition from academia to the professional world. The
                course content provided by Neco Jobs was structured in a way
                that allowed me to grasp complex HR concepts and practices.
                Learning about recruitment strategies, payroll processes, and
                compliance regulations gave me a solid foundation to kickstart
                my HR career. The trainers' guidance and practical insights were
                invaluable in building my confidence. I'm grateful for this
                opportunity and would recommend it to any aspiring HR
                professional.
              </h5>
              <div class="reviewImage">
                <v-row class="reviewflex">
                  <v-col md="5">
                    <img :src="require(`@/assets/BinuShivabhakti.jpg`)" />
                  </v-col>
                  <v-col md="7">
                    <p>Binu Shivabhakti</p>
                    <div class="innerstar">
                      <v-icon>mdi-star</v-icon>
                      <v-icon>mdi-star</v-icon>
                      <v-icon>mdi-star</v-icon>
                      <v-icon>mdi-star</v-icon>
                      <v-icon>mdi-star</v-icon>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="iconText2">
              <h5>
                The HR training I attended with NecoJobs was incredibly
                insightful and professionally delivered. Melisha ma'am provided
                comprehensive guidance on various HR topics, making complex
                concepts easy to understand. I appreciated the interactive
                nature of the sessions, which encouraged active participation
                and engagement. Overall, it was a valuable learning experience
                that has equipped me with practical skills to excel in my HR
                role. I highly recommend this training to anyone looking to
                enhance their HR knowledge and capabilities.
              </h5>
              <div class="reviewImage">
                <v-row class="reviewflex">
                  <v-col md="5">
                    <img :src="require(`@/assets/Rubina.jpeg`)" />
                  </v-col>
                  <v-col md="7">
                    <p>Rubina Kapali</p>
                    <div class="innerstar">
                      <v-icon>mdi-star</v-icon>
                      <v-icon>mdi-star</v-icon>
                      <v-icon>mdi-star</v-icon>
                      <v-icon>mdi-star</v-icon>
                      <v-icon>mdi-star</v-icon>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
        </slick>
      </div>
    </v-container>

    <v-container
      fluid
      class="bg-backimage"
      :style="`background-image:url(${require('@/assets/bgtraining.png')})`"
    >
      <v-container class="container-custom">
        <v-row class="mt-5">
          <div class="boxinside d-flex flex-wrap mx-3 mb-10">
            <v-col md="5" cols="12">
              <h2 class="text-bold mb-3">Register Using This Form</h2>
            </v-col>
            <v-col md="7" cols="12">
              <v-form>
                <v-text-field
                  v-model="fullName"
                  outlined
                  placeholder="Full Name"
                  background-color="white"
                />
                <v-text-field
                  v-model="email"
                  outlined
                  placeholder="Email"
                  background-color="white"
                />
                <v-text-field
                  v-model="phone"
                  outlined
                  placeholder="Phone number"
                  background-color="white"
                />
                <v-text-field
                  v-model="address"
                  outlined
                  placeholder="Address"
                  background-color="white"
                />
                <v-text-field
                  v-model="educationQualification"
                  outlined
                  placeholder="Education Qualification"
                  background-color="white"
                />

                <v-select
                  :items="allTrainings.results.list"
                  v-model="course"
                  item-text="trainingTitle"
                  item-value="trainingTitle"
                  outlined
                  placeholder="Choose Course"
                  background-color="white"
                ></v-select>
                <v-select
                  v-model="shift"
                  :items="shifts"
                  item-text="name"
                  item-value="value"
                  outlined
                  placeholder="Choose Shift"
                  background-color="white"
                ></v-select>
                <v-textarea
                  v-model="description"
                  placeholder="Your Expectations"
                  rows="3"
                  background-color="white"
                  outlined
                >
                </v-textarea>
                <v-btn class="submitBtn" @click="submitForm" :loading="loading">
                  Register
                </v-btn>
              </v-form>
            </v-col>
          </div>
        </v-row>
      </v-container>
    </v-container>

    <v-snackbar v-model="snackbar">
      <span> {{ message }} </span>
    </v-snackbar>
  </section>
</template>

<script>
import Searchboxtraining from "@/components/frontend/SearchboxTraining";
import { mapActions, mapGetters } from "vuex";
import Slick from "vue-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default {
  name: "Trainings",
  components: { Searchboxtraining, Slick },
  data: () => ({
    slickOptions: {
      slidesToShow: 6,
      arrows: true,
      slidesToScroll: 3,
      autoplay: true,
      infinite: true,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            arrows: false,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
      ],
    },
    slickOptionss: {
      slidesToShow: 2,
      arrows: true,
      slidesToScroll: 1,
      autoplay: true,
      infinite: true,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            arrows: false,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
      ],
    },
    fullName: "",
    email: "",
    phone: "",
    address: "",
    educationQualification: "",

    shifts: [
      {
        name: "Morning Shift",
        value: "Morning Shift",
      },
      {
        name: "Day Shift",
        value: "Day Shift",
      },
      {
        name: "Evening Shift",
        value: "Evening Shift",
      },
    ],
    course: "",
    shift: "",
    description: "",
    loading: false,
    snackbar: false,
    message: "",
    loadingTraining: true,
  }),

  methods: {
    ...mapActions([
      "getTrainingListingFrontend",
      "postRegistration",
      "getCompanyLogos",
      "getSingleMetaPage",
    ]),

    async submitForm() {
      this.loading = true;
      const data = {
        fullName: this.fullName,
        email: this.email,
        phone: this.phone,
        address: this.address,
        educationQualification: this.educationQualification,
        course: this.course,
        shift: this.shift,
        description: this.description,
      };
      await this.postRegistration(data);
      this.fullName = "";
      this.email = "";
      this.phone = "";
      this.address = "";
      this.educationQualification = "";
      this.course = "";
      this.shift = "";
      this.description = "";

      this.message = this.allTrainingRegistration.message;
      this.loading = false;
      this.snackbar = true;
    },
    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },

    reInit() {
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    },
  },
  computed: {
    ...mapGetters([
      "allTrainings",
      "allTrainingRegistration",
      "allCompanyLogos",
      "allMeta",
    ]),
  },
  metaInfo() {
    return {
      title: this.allMeta && this.allMeta.title,
      meta: [
        {
          name: "description",
          content: this.allMeta && this.allMeta.description,
        },
        {
          name: "keywords",
          content: this.allMeta && this.allMeta.keywords,
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.necojobs.com.np/training",
        },
      ],
    };
  },
  async created() {
    await this.getTrainingListingFrontend({ limit: 10, skip: 1 });
    await this.getCompanyLogos();
    await this.getSingleMetaPage("training");
    this.loadingTraining = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.table {
  box-shadow: 0px -1px 5px 2px rgba(0, 0, 0, 0.1);

  thead {
    border-bottom: 2px solid $menucolor;
    background: $tagcolor;
  }
  th {
    font-size: $normal_lfont !important;
    color: $white !important;
    font-size: $iconfont;
  }
  td {
    div {
      padding: 20px 10px;
    }
  }
  margin-bottom: 20px;
}
h1 {
  font-size: $titlefont;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
}
.boxinside {
  transition: width 235ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 20px;
  margin-bottom: 20px;
  padding: 20px;
  width: 100%;
  margin-bottom: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }
}
.text-justify {
  text-align: justify;
}
.logobox {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  img {
    width: 150px;
  }
}
.aboutcontent {
  font-size: $midfont;
  margin-top: 10px;
}
.actionbutton {
  display: flex;
  justify-content: center;
  a {
    text-decoration: none;
  }
  .v-icon {
    font-size: $iconfont;
  }
  .socialshare {
    box-shadow: 0px 1px 14px 1px rgba(222, 222, 222, 1);
    padding: 10px;
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
  }
  li {
    margin-left: 0;
    margin-right: 15px;
    position: relative;
    cursor: pointer;
    &:hover {
      .socialshare {
        display: flex;
        background: $white;
        flex-flow: row;
      }
    }
  }
}
.reviewImage {
  padding-top: 10px;
  .reviewflex {
    align-items: center;
  }
  p {
    font-size: 14px;
    font-weight: 600;
    padding: 0 !important;
  }
}

.jobslistBox {
  margin-bottom: 10px;
  margin-top: 10px;
  border: 1px solid #c7c1c1;
}
.jobslist {
  margin-bottom: 10px;
  margin-top: 10px;
}
.btn-actions {
  font-size: $normalfont;
  margin-top: 10px;
  li {
    margin-left: 0;
    padding: 10px;
    background: red;
    padding: 5px 20px;
    font-size: $midfont;
    cursor: pointer;
    border-radius: 25px;
    color: $white;
    &:first-of-type {
      background: $tagcolor;
    }
    &:nth-of-type(2) {
      background: $greentva;
    }
    &:nth-of-type(3) {
      background: $white;
      border: 1px solid $tagcolor;
      color: $tagcolor;
    }
  }
}
small {
  a {
    text-decoration: none;
    color: $tagcolor;
    .v-icon {
      font-size: $iconfont;
      text-decoration: none;
    }
  }
}
.mr-btm {
  margin-bottom: 40px;
}
.submitBtn {
  width: 100%;
  background: #4682da !important;
  color: #fff;
  border-radius: 10px;
  min-height: 55px;
}
.listJobs {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-left: 0;
  list-style: disc;
  li {
    margin-left: 0;
    list-style: disc;
  }
}
.bg-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.bg-backimage {
  background-size: cover;
  background-position: bottom;
}
.bannerTraining {
  background: url("../../../assets/banner.png") no-repeat center right;
  background-size: contain;
  height: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  h1 {
    text-align: left;
    font-weight: 800;
    font-size: 30px;
  }
  p {
    text-align: left;
  }
  .primary {
    background: $tagcolor;
    color: $white;
    min-height: 40px;
    width: 200px;
  }
  @media only screen and (max-width: 991px) {
    justify-content: flex-start;
    height: 900px;
    background: url("../../../assets/banner.png") no-repeat left bottom;
  }
}
.iconText {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  text-align: center;
  @media only screen and (max-width: 991px) {
    justify-content: flex-start;
  }
  img {
    width: 60px;
    margin-right: 10px;
  }
}
.iconText2 {
  display: flex;
  align-items: start;
  justify-content: start;
  flex-flow: column;
  text-align: left;
  padding-bottom: 10px;
  .v-icon {
    color: #f8c921;
  }
  h5 {
    font-size: 14px;
    font-weight: 400;
    min-height: 240px;
    height: auto;
  }
  p {
    font-size: 16px;
    font-weight: bold;
    padding-top: 5px;
  }
  @media only screen and (max-width: 991px) {
    justify-content: flex-start;
  }
  img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
    border: 1px solid gray;
  }
}
.shadownBox {
  box-shadow: 0px 1px 14px 1px rgba(222, 222, 222, 1);
  padding: 10px;
  margin-bottom: 20px;
}
.placementBox {
  h2 {
    font-weight: bold;
    text-align: center;
    font-size: 30px;
  }
  p {
    text-align: center;
  }
}
.text-bold {
  font-weight: 600;
  font-size: 40px;
}
.imageBox {
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  height: 100px;
}
@media screen and (max-width: 600px) {
  .bannerTraining {
    background: url("../../../assets/banner.png") no-repeat center bottom;
    background-position-x: center;
    background-size: contain;
  }
  .shadownBox {
    width: 100% !important;
  }
  .listJobs {
    padding: 0 10px;
  }
}
</style>
